body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color:#FFF;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Pacifico';
  src: local('Pacifico'), url(/public/Pacifico-Regular.ttf) format('truetype');
}

#container {
  margin:0 auto;
  max-width: 750px;
  padding:5%;
}

#logo {
  font-family: 'Pacifico';
  font-size:48px;
  text-align:center;
  margin-bottom:40px;
}

#header-nav {
  text-align:center;
  margin-bottom:10%;
}

#sign-out-button {
  margin:0 auto;
}

#sign-in-view form {
  margin:0 auto;
  max-width:500px;
}

button#signin span.spinner-border {
  margin-right:5px;
}

nav {
  margin-bottom:30px;
}